<template>
  <div>
    <!-- 搜索 -->
    <a-card class="search-bar">
      <a-form-model ref="searchForm" :model="searchForm">
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-model-item label="课件名称" prop="name">
              <a-input
                v-model.trim="searchForm.name"
                allow-clear
                placeholder="支持模糊搜索"
                @keyup.native.enter="search()"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="工种" prop="wt_id">
              <a-select
                v-model="searchForm.wt_id"
                allow-clear
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                placeholder="选择工种"
              >
                <a-select-option
                  v-for="(item, index) in workTypes"
                  :key="item.kid"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="课件级别" prop="level_id">
              <a-select
                v-model="searchForm.level_id"
                allow-clear
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                placeholder="选择课件级别"
              >
                <a-select-option v-for="level in levels" :key="level.kid">
                  {{ level.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-button :loading="loading" type="primary" @click="search()"
                >查 询</a-button
              >
              <a-button @click="reset()">重 置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <!-- 内容 -->
    <a-card class="main-content">
      <template slot="title">
        <span class="table-title">课件列表</span>
        <router-link to="/courseware/detail">
          <a-button type="primary" icon="plus">新增</a-button>
        </router-link>
      </template>
      <a-table
        :loading="loading"
        rowKey="kid"
        :columns="columns"
        :data-source="datalist"
        :pagination="pagination"
        @change="onPageChange"
      >
        <span slot="action" slot-scope="record">
          <a-button type="link" @click="getCourseInfo(record.kid)"
            >查看</a-button
          >
          <router-link
            :to="{ path: '/courseware/detail', query: { id: record.kid } }"
          >
            <a-button type="link">编辑</a-button>
          </router-link>
          <a-popconfirm
            title="您确认要删除该课件吗？"
            @confirm="del(record.kid)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </a-card>

    <!-- 弹框 -->
    <a-modal
      title="课件详情"
      :visible="dialog"
      width="1100px"
      destroyOnClose
      :footer="null"
      @cancel="dialog = false"
    >
      <a-spin :spinning="spinning">
        <a-row type="flex" :gutter="[8, 8]">
          <a-col :span="14">
            <a-card>
              <div id="dplayer"></div>

              <a-divider />

              <h2>{{ info.name }}</h2>
              <!-- 层级 -->
              <a-breadcrumb separator=">">
                <a-breadcrumb-item>
                  <a-tag color="#108ee9">{{ info.str_type }}</a-tag>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                  <a-tag color="#108ee9">{{ info.str_level }}</a-tag>
                </a-breadcrumb-item>
                <a-breadcrumb-item v-if="info.str_wtid">
                  <a-tag color="#108ee9">
                    {{ info.str_wtid }}
                  </a-tag>
                </a-breadcrumb-item>
              </a-breadcrumb>
              <!-- 关联工种 -->
              <span v-if="info.str_wts">
                <a-tag
                  v-for="(item, index) in info.str_wts"
                  :key="index"
                  color="orange"
                >
                  {{ item }}
                </a-tag>
              </span>
            </a-card>
          </a-col>
          <a-col :span="10">
            <a-card class="exam">
              <a-collapse v-model="activePanel" expandIconPosition="right">
                <a-collapse-panel
                  v-for="(item, index) in info.exams"
                  :key="String(item.kid)"
                >
                  <div slot="header" class="header-content">
                    <a-tag :color="item.sort == 1 ? '#2db7f5' : '#f50'">
                      {{ item.sort == 1 ? "选择题" : "判断题" }}
                    </a-tag>
                    <span class="number">{{
                      9 > index ? "0" + (index + 1) : index + 1
                    }}</span
                    >{{ item.title }}
                  </div>

                  <div class="panel-content">
                    <div class="ques-info">
                      <strong>【题目】</strong>{{ item.title }}
                      <div class="options">
                        <strong>【选项】</strong>
                        <ul>
                          <li v-for="(opt, idx) in item.opts" :key="idx">
                            <span v-if="item.sort == '1'" class="option-label"
                              >{{ String.fromCharCode(65 + idx) }}.</span
                            >
                            {{ opt }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="item.path_img" class="ques-image">
                      <img :src="item.img_view" alt="" />
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
              <div style="padding: 12px"></div>
            </a-card>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import DPlayer from 'dplayer'

export default {
  data() {
    return {
      loading: false,
      workTypes: [],
      levels: [],
      searchForm: {
        name: '',
        wt_id: undefined,
        level_id: undefined,
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '序号', key: 'kid', dataIndex: 'kid', align: 'center', width: 65, customRender: (text, record, index) =>`${(this.pagination.current - 1) *this.pagination.pageSize + index + 1}`},
        { title: '课件名称', key: 'name', dataIndex: 'name', ellipsis: true },
        { title: '课件种类', key: 'sort', dataIndex: 'sort', align: 'center', ellipsis: true },
        { title: '培训类型', key: 'level', dataIndex: 'level', align: 'center', ellipsis: true },
        { title: '课件级别', key: 'level_sub', dataIndex: 'level_sub', align: 'center', ellipsis: true },
        { title: '工种', key: 'wt_str', dataIndex: 'wt_str', align: 'center', ellipsis: true },
        { title: '关联工种', key: 'wt_link', dataIndex: 'wt_link', align: 'center', ellipsis: true },
        { title: '操作', key: 'action', scopedSlots: { customRender: 'action' }, align: 'center' }
      ],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: (total) => `共 ${total} 条`
      },
      datalist: [],
      // 弹框
      dialog: false,
      spinning: false,
      info: {},
      activePanel: []
    }
  },
  mounted() {
    this.getCourseList()
    this.allWorkTypes()
    this.allLevels()
  },
  activated() {
    // 缓存刷新数据
    this.getCourseList()
  },
  methods: {
    search() {
      this.searchForm.page = 1
      this.pagination.current = 1
      this.getCourseList()
    },
    reset() {
      this.$refs.searchForm.resetFields();
      this.searchForm.page = 1
      this.searchForm.list_rows = 30
      this.pagination.current = 1
      this.pagination.pageSize = 30
      this.getCourseList()
    },
    getCourseList() {
      this.loading = true
      this.api.getCourseList(this.searchForm).then(res => {
        this.datalist = res.data.data
        this.pagination.total = res.data.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onPageChange(pagination) {
      this.pagination = pagination
      this.searchForm.page = pagination.current;
      this.searchForm.list_rows = pagination.pageSize;
      this.getCourseList()
    },
    getCourseInfo(id) {
      this.spinning = true
      this.api.getCourseInfo({ kid: id }).then(res => {
        console.log('3333333', res)
        this.spinning = false
        this.dialog = true
        this.info = res.data.data
        if (this.info.str_wtids) {
          this.$set(this.info, 'str_wts', this.info.str_wtids.split(','))
        }
        if (this.info.file_path) {
          this.$nextTick(() => {
            const dp = new DPlayer({
              container: document.getElementById('dplayer'),
              video: {
                url: this.info.video_url,
                pic: this.info.cover_view
              }
            })
            let videoDom = document.querySelector('.dplayer-video')
            dp.on('loadstart', function () {
              videoDom.classList.add('noFullScreen')
            })
            dp.on('fullscreen', function () {
              videoDom.classList.remove('noFullScreen')
            })
            dp.on('fullscreen_cancel', function () {
              videoDom.classList.add('noFullScreen')
            })
          })
        }
        if (this.info.exams.length != 0) {
          this.activePanel = this.info.exams.map(item => String(item.kid))
        } else {
          this.activePanel = []
        }
      }).catch(err => {
        this.spinning = false
      })
    },
    del(courseId) {
      this.api.delCourse({ kid: courseId }).then(res => {
        this.$message.success(res.data.msg)
        this.getCourseList()
      })
    },
    allWorkTypes() {
      this.api.allWorkTypes().then(res => {
        this.workTypes = res.data.data
      })
    },
    allLevels() {
      this.api.allLevels({ level_id: 1 }).then(res => {
        this.levels = res.data.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-breadcrumb {
  .ant-tag {
    margin: 0;
  }
}
/deep/.ant-modal-body {
  padding: 50px 24px;
}
.ant-collapse {
  background: #fff;
}
.exam {
  height: 100%;
  overflow-y: auto;
  /deep/.ant-card-body {
    height: 0;
  }
}
.header-content {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/deep/.noFullScreen {
  max-height: 400px;
  object-fit: contain;
}
.options {
  margin-top: 5px;
  ul {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
  }
  .option-label {
    margin-right: 6px;
  }
}
.panel-content {
  display: flex;
  justify-content: space-between;
}
.ques-info {
  flex: 1;
}
.ques-image {
  width: 100px;
  height: 80px;
  border: 1px solid #ccc;
}
.ques-image img {
  width: 100px;
  height: auto;
  object-fit: scale-down;
  // margin-left: 5px;
}
// .exam {
//   list-style: none;
//   padding: 0;
//   .exam-item {
//     display: flex;
//     padding: 10px 0;
//     border-bottom: 1px dashed #dedede;
//   }
//   .seq {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     .number {
//       font-size: 24px;
//       font-weight: bold;
//       font-family: 'Courier New', Courier, monospace;
//     }
//     .ant-tag {
//       margin: 0;
//     }
//   }
//   .ques {
//     flex: 1;
//     padding: 0 12px;
//     .ques-title {
//       color: #333;
//     }
//     .ques-detail {
//       display: flex;
//       padding: 0 10px;
//       line-height: 2em;
//       margin-top: 10px;
//       .options {
//         flex: 1;
//         list-style: none;
//         padding: 0;
//       }
//       .answer {
//         font-size: 16px;
//         font-weight: bolder;
//         padding: 0 10px;
//         background: #d6e0f0;
//         color: #000;
//       }
//     }
//   }
// }
</style>